<!--
 * @Author: Link
 * @Date: 2020-12-15 09:15:19
 * @LastEditTime: 2021-11-03 10:03:57
-->
<template>
  <div class="layout">
    <!-- <transition name="slide-right">
      <router-view></router-view>
    </transition> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
      <!--这里是会被缓存的组件-->
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!--这里是不会被缓存的组件-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    foo: 'bar'
  }),
  components: {},
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    ...mapActions({})
  },
  created() {
    this.$store.dispatch('user/getBanList')
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.layout {
  max-width: 580px;
  min-width: 350px;
  min-height: 100vh;
  margin: 0 auto;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
}
</style>
<style lang="less">
.van-button {
  border-radius: 6px !important;
  border: none;
}
</style>
